const {Streamer, LaunchblogEvent} = require('@epi/epi-telemetry')

const Utils = require('./Utils')

class Telemetry {

    static async init (baseData) {
        if (Utils.isDev()) {
            return
        }

        Telemetry.streamer = new Streamer({
            endpoint: process.env.GATSBY_EPI_TELEMETRY_URL,
            transportType: 'WEB'
        })

        Telemetry.baseData = baseData

        await Telemetry.streamer.init()

        Telemetry.initialised = true
    }

    static add (data) {
        if (!Telemetry.initialised) {
            return
        }

        const event = new LaunchblogEvent({
            ...Telemetry.baseData,
            releaseStage: process.env.GATSBY_EPI_RELEASE_ENV,
            releaseVersion: process.env.GATSBY_EPI_RELEASE_VERSION,
            releaseBuild: process.env.GATSBY_EPI_RELEASE_BUILD,
            ...data
        })

        Telemetry.streamer.addEvent(event)


    }

    static addPageView (data) {
        Telemetry.add({
            action: 'page-view',
            ...data
        })
    }
    
}

export default Telemetry

